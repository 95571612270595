import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "../../store/thunks/invoiceThunk";
import InvoiceTable from "./InvoiceTable";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import InvoicePaymentModal from "../../modals/InvoicePaymentModal";
import Table from "../../applicationUi/components/Table";

const InvoicesPage = () => {
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices?.invoices);
  const isLoading = useSelector(
    (state) => state.invoices?.status === "loading"
  );
  const currentPage = useSelector((state) => state.invoices?.currentPage || 1);
  const totalPages = useSelector((state) => state.invoices?.totalPages || 1);
  const totalInvoices = useSelector(
    (state) => state.invoices?.totalInvoices || 0
  );
  const error = useSelector((state) => state.invoices?.error);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoices(currentPage));
  }, [dispatch, currentPage]);

  const handleRowClick = (clickedInvoice) => {
    const invoice = invoices.find((inv) => inv._id === clickedInvoice);
    setSelectedInvoice(invoice);
    setShowInvoiceGenerator(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedInvoice(null);
  };

  const handleInvoiceGeneratorClose = () => {
    setShowInvoiceGenerator(false);
    setSelectedInvoice(null);
  };

  const handlePageChange = (newPage) => {
    dispatch(fetchInvoices(newPage));
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {isLoading ? (
        <p>Loading invoices...</p>
      ) : (
        <div>
          <div className="py-4">
            <SearchDropdown
              prefix=":invoice:"
              displayFields={[
                "invoiceId",
                "client.fullName",
                "clinician.fullName",
                "amount",
                "status",
              ]}
              onSelection={(invoice) => {
                setSelectedInvoice(invoice);
                setShowModal(true);
              }}
              placeholder={"Search for an invoice using Invoice ID"}
            />
          </div>

          <Table
            columns={[
              {
                header: "Invoice ID",
                accessor: "invoiceId",
              },
              {
                header: "Service Date",
                accessor: (row) => {
                  const date = new Date(row.serviceDate);
                  return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                },
              },
              {
                header: "Invoice Date",
                accessor: (row) => {
                  const date = new Date(row.createdAt);
                  return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                },
              },
              {
                header: "Client Name",
                accessor: (row) =>
                  row.client
                    ? `${row.client.firstName || "Unknown"} ${
                        row.client.lastName || ""
                      }`
                    : "Unknown",
              },
              {
                header: "Clinician",
                accessor: (row) =>
                  row.clinician
                    ? `${row.clinician.firstName || "Unknown"} ${
                        row.clinician.lastName || ""
                      }`
                    : "Unknown",
              },
              {
                header: "Amount",
                accessor: (row) => `$${row.amount?.toFixed(2) || "0.00"}`,
              },
              {
                header: "Split Invoice?",
                accessor: (row) =>
                  row.splitBilling && row.splitBilling.isSplit ? "Yes" : "No",
              },
              {
                header: "Status",
                accessor: "status",
              },
            ]}
            data={invoices}
            onRowClick={handleRowClick}
            title="Invoices"
            description={`List of all invoices (${totalInvoices} total)`}
            showPagination={true}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />

          {showModal && selectedInvoice && (
            <InvoicePaymentModal
              invoice={selectedInvoice}
              onClose={handleModalClose}
            />
          )}

          {showInvoiceGenerator && selectedInvoice && (
            <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
              <div className="relative bg-white rounded-lg shadow-xl max-w-7xl w-full m-4">
                <div className="p-4 sm:p-6 lg:p-8">
                  <button
                    onClick={handleInvoiceGeneratorClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                    aria-label="Close">
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <InvoiceGenerator
                    data={selectedInvoice}
                    initialViewMode={true}
                    onClose={handleInvoiceGeneratorClose}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoicesPage;
